@import '../../../../styles/customMediaQueries.css';

.root {
  
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.code {
  composes: formMargins;
}

.errorPlaceholder {
  color: var(--colorFail);
  margin: 12px 0;
  @media (--viewportMedium) {
    width: 100%;
    min-height: 32px;
  }
}

.submitButton {
  composes: formMargins;
}
